import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ForgotPassowrdProps {}

const ForgotPassowrd: React.FunctionComponent<ForgotPassowrdProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Link to="/">
        {"<-"}
        {t("Back")}
      </Link>
      <h1>{t("Forgot password")}</h1>
    </>
  );
};

export default ForgotPassowrd;
