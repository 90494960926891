import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KitModel } from "../../Api/Models/product.model";
import { StockModel } from "../../Api/Models/stock.model";
import { kitService } from "../../Api/Services/KitService";
import { stockService } from "../../Api/Services/StockService";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";
import StockOrder from "./StockOrder";
import KitIcon from "../../Assets/gfx/kit_icon.png";

interface StockListProps {}

const StockList: React.FunctionComponent<StockListProps> = () => {
  const { t } = useTranslation();

  const [stocks, setStocks] = useState<StockModel[]>([]);
  const { startLoading, finnishLoading } = useLoading();
  const [kits, setKits] = useState<KitModel[]>([]);
  const [stockCorrectionProductId, setStockCorrectionProductId] = useState<
    number | null
  >(null);
  const { showInfo } = useInfoModal();

  const stockCorrectionTextRef = useRef<HTMLTextAreaElement | null>(null);

  const [selectedStockItems, setSelectedStockItems] = useState<StockModel[]>(
    []
  );

  useEffect(() => {
    loadStocks();
    loadKits();
    //eslint-disable-next-line
  }, []);

  const loadKits = () => {
    startLoading("get-kit-list");
    kitService
      .listKits()
      .then((kits) => {
        setKits(kits);
      })
      .finally(() => {
        finnishLoading("get-kit-list");
      });
  };

  const loadStocks = () => {
    startLoading("get-stock-list");
    stockService
      .listStocks()
      .then((stocks) => {
        setStocks(stocks);
      })
      .finally(() => {
        finnishLoading("get-stock-list");
      });
  };

  const disassemble = (kit: KitModel) => {
    startLoading("disassemble-kit");
    kitService
      .disassembleKit(kit)
      .then(() => {
        let kitsState = [...kits];
        kitsState = kitsState.map((i) => {
          if (i.id === kit.id) {
            const newCount = i.dailyCount - 1;
            return {
              ...i,
              dailyCount: newCount,
              available: kit.available - 1,
            };
          }
          return i;
        });

        setKits(kitsState);
        loadStocks();
      })
      .finally(() => {
        finnishLoading("disassemble-kit");
      });
  };

  const sendStockCorrectionRequest = () => {
    if (stockCorrectionProductId && stockCorrectionTextRef.current) {
      startLoading("send-stock-correction");
      stockService
        .stockCorrectionRequest(
          stockCorrectionProductId,
          stockCorrectionTextRef.current?.value
        )
        .then((stocks) => {
          setStockCorrectionProductId(null);
          if (stockCorrectionTextRef.current) {
            stockCorrectionTextRef.current.value = "";
          }
          showInfo("Stock correction request sent!");
        })
        .finally(() => {
          finnishLoading("send-stock-correction");
        });
    }
  };

  const closeStockOrder = () => {
    setSelectedStockItems([]);
  };

  const uniqueProductStocks = (): StockModel[] => {
    let uniqueProductStocks: StockModel[] = [];
    stocks.forEach((stock) => {
      if (!uniqueProductStocks.some((x) => x.productId === stock.productId)) {
        uniqueProductStocks.push(stock);
      }
    });

    return uniqueProductStocks;
  };

  if (selectedStockItems.length > 0) {
    return <StockOrder stocks={selectedStockItems} close={closeStockOrder} />;
  }

  return (
    <div className="view-container background-3">
      <div
        className={"modal" + (!stockCorrectionProductId ? " hidden" : "")}
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal__dialog modal-dialog--full-height">
          <div className="modal__header">
            <div className="modal__heading">
              {t("Stock correction request")}
            </div>
            <div
              className="modal__close"
              onClick={() => {
                setStockCorrectionProductId(null);
                if (stockCorrectionTextRef.current) {
                  stockCorrectionTextRef.current.value = "";
                }
              }}
            >
              ✖
            </div>
          </div>
          <div className="modal__body">
            <label>{t("Message")}</label>
            <textarea
              ref={stockCorrectionTextRef}
              placeholder={t("Message") ?? ""}
              className="bg-lightgray mb-6"
            ></textarea>
            <div className="flex justify-center mb-8 mt-5">
              <button
                type="button"
                className="button button--blue"
                onClick={() => sendStockCorrectionRequest()}
              >
                {t("Send")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="view-content">
        <div className="heading text-center">{t("Stock level")}</div>

        <div className="limited-width-content">
          {/*<div className="flex justify-center mb-8">
              <div className="select-rounded-wrapper">
                <select>
                  <option value="Warehouse # 1">Warehouse # 1</option>
                  <option value="Warehouse # 2">Warehouse # 2</option>
                </select>
              </div>
          </div>*/}
          <div className="card-list mb-12 limited-height-content">
            {kits.map((kit) => (
              <div className="card" key={kit.id}>
                <div className="flex justify-between gap-3">
                  <div className="flex items-center gap-3">
                    <img
                      src={KitIcon}
                      className="image image--w35"
                      alt="Product"
                    />
                    <div>
                      <div className="card__product-title">{kit.name}</div>
                      {kit.available && (
                        <div className="text-small">
                          {t("Available")}:&nbsp;
                          <strong className="text-green">
                            {kit.available}
                          </strong>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <button
                      type="button"
                      className="button-remove"
                      onClick={() => {
                        disassemble(kit);
                      }}
                    >
                      <i className="icon-remove"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <hr />
            {uniqueProductStocks().map((stock, index) => (
              <div className="stock-list-card" key={index}>
                <div className="stock-list-card__image">
                  {stock.productImage && (
                    <img src={stock.productImage} height="50px" alt="Product" />
                  )}
                </div>
                <div className="stock-list-card__content">
                  <div className="stock-list-card__title">
                    {stock.productName}
                  </div>
                  <p
                    className="link"
                    onClick={() => {
                      setStockCorrectionProductId(stock.productId);
                    }}
                  >
                    Stock correction?
                  </p>
                  {stock.variationId && (
                    <div className="stock-list-card__info">
                      {stocks
                        .filter((x) => x.productId === stock.productId)
                        .map((stockVariation) => (
                          <div key={stockVariation.variationId}>
                            {stockVariation.variationName}&nbsp;
                            {stockVariation.quantity}pcs
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                <div className="stock-list-card__right">
                  <div className="stock-list-card__pc">
                    {stock.variationId
                      ? stocks
                          .filter((x) => x.productId === stock.productId)
                          .reduce((accumulator, object) => {
                            return accumulator + object.quantity;
                          }, 0)
                      : stock.quantity}{" "}
                    {t("pc")}
                  </div>
                  <button
                    className="button-add"
                    onClick={() => {
                      const selectedStocks = stock.variationId
                        ? stocks.filter((x) => x.productId === stock.productId)
                        : [stock];
                      setSelectedStockItems(selectedStocks);
                    }}
                  >
                    <i className="icon-add"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mb-8 mt-5 ">
            <Link to="/stock/orders/list" className="button button--blue">
              {t("Orders history")}
            </Link>
          </div>

          <div className="flex justify-center">
            <Link to="/menu" className="button">
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockList;
