import { useState } from "react";
import EnglishFlag from "../Assets/flags/en.svg";
import i18n, { languages } from "../i18n";

interface LanguageSelectorProps {}

const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = () => {
  const [showLanguageSelectModal, setShowLanguageSelectModal] =
    useState<boolean>(false);

  return (
    <>
      <div
        className="flex justify-center mb-4"
        onClick={() => setShowLanguageSelectModal(true)}
      >
        <div className="language-selector">
          <img
            src={
              languages.find((x) => x.key === i18n.language)?.flag ??
              EnglishFlag
            }
            alt="Flag"
          />
          <div className="flex items-center gap-2">
            {languages.find((x) => x.key === i18n.language)?.name ?? "English"}
            <i className="icon-arrow-down"></i>
          </div>
        </div>
      </div>
      <div
        className={"modal " + (showLanguageSelectModal ? "" : "hidden")}
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal__dialog">
          <div className="modal__body">
            <ul className="modal__list">
              {languages.map((language) => (
                <li key={language.key}>
                  <div
                    className="flex items-center gap-4"
                    onClick={() => {
                      i18n.changeLanguage(language.key);
                      localStorage.setItem("language", language.key);
                      setShowLanguageSelectModal(false);
                      let htmlElement = document.getElementById("html");
                      if (htmlElement) {
                        htmlElement.dir = language.rtl ? "rtl" : "ltr";
                        htmlElement.lang = language.key;
                      }
                    }}
                  >
                    <img
                      src={language.flag}
                      style={{ height: "34px" }}
                      alt="Flag"
                    />
                    {language.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LanguageSelector;
