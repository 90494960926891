import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { WarrantyModel, WarrantyStatus } from "../../Api/Models/warranty.model";
import { LaravelValidationError } from "../../Api/request";
import { warrantyService } from "../../Api/Services/WarrantyService";
import LongTextDisplay from "../../Components/LongTextDisplay";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";
interface ShowWarrantyProps {}

const ShowWarranty: React.FunctionComponent<ShowWarrantyProps> = () => {
  const { startLoading, finnishLoading } = useLoading();

  let { warrantyId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showInfo } = useInfoModal();
  const { t } = useTranslation();

  const [warrantyData, setWarrantyData] = useState<WarrantyModel>(
    state?.warrantyData
  );

  useEffect(() => {
    if (!warrantyData) {
      if (!warrantyId) {
        showInfo(t("Invalid QR code!"));
        navigate("/menu");
        return;
      }

      startLoading("get-warranty");

      warrantyService
        .getWarranty(parseInt(warrantyId))
        .then((warrantyData) => {
          setWarrantyData(warrantyData);
        })
        .catch((e) => {
          showInfo(t("Invalid QR code!"));
          navigate("/menu");
        })
        .finally(() => {
          finnishLoading("get-warranty");
        });
    }
    //eslint-disable-next-line
  }, []);

  const handleWarrantyDispatched = () => {
    startLoading("set-warranty-dispatched");

    warrantyService
      .dispatch(warrantyData.id)
      .then((newWarrantyData) => {
        setWarrantyData(newWarrantyData);
        showInfo(t("Product successfully exchanged!"));
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("set-warranty-dispatched");
      });
  };

  const handleWarrantyUndoDispatched = () => {
    startLoading("undo-warranty-dispatched");

    warrantyService
      .undoDispatch(warrantyData.id)
      .then((newWarrantyData) => {
        setWarrantyData(newWarrantyData);
        showInfo(t("Product exchange status updated!"));
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("undo-warranty-dispatched");
      });
  };

  const handleWarrantyRefused = () => {
    startLoading("set-warranty-refused");

    warrantyService
      .refuse(warrantyData.id)
      .then((newWarrantyData) => {
        setWarrantyData(newWarrantyData);
        showInfo(t("Product exchange declined!"));
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("set-warranty-refused");
      });
  };

  const handleWarrantyUndoRefused = () => {
    startLoading("undo-warranty-refused");

    warrantyService
      .undoRefuse(warrantyData.id)
      .then((newWarrantyData) => {
        setWarrantyData(newWarrantyData);
        showInfo(t("Product exchange status updated!"));
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("undo-warranty-refused");
      });
  };

  if (!warrantyData) {
    return (
      <div className="view-container background-3">
        <div className="view-content">
          <div className="heading text-center">{t("Product exchange")}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="view-container background-2">
      <div className="view-content">
        <div className="heading text-center">{t("Product exchange")}</div>
        <div className="content-card mb-8">
          <table className="info-table">
            <tbody>
              <tr>
                <th>{t("Full Name")}:</th>
                <td>
                  {warrantyData.firstName} {warrantyData.lastName}
                </td>
              </tr>
              <tr>
                <th>{t("Rider ID")}:</th>
                <td>{warrantyData.riderId}</td>
              </tr>
              <tr>
                <th>{t("Email")}:</th>
                <td>
                  <LongTextDisplay text={warrantyData.email} />
                </td>
              </tr>
              <tr>
                <th className="lighter">{t("Product Name")}</th>
                <th className="lighter">#</th>
              </tr>
              <tr className="tr-product">
                <th>{warrantyData.productName}</th>
                <td>1 {t("pc")}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-center gap-8 mb-4">
          {warrantyData.status !== WarrantyStatus.DISPATCHED && (
            <button
              type="button"
              className="button-2 button-2--approved"
              onClick={handleWarrantyDispatched}
            >
              <div className="button-2__icon">
                <i className="icon-approve"></i>
              </div>
              <span className="button-2__text">{t("Approve")}</span>
            </button>
          )}

          {warrantyData.status === WarrantyStatus.DISPATCHED && (
            <button
              type="button"
              className="button-2 button-2--undo"
              onClick={handleWarrantyUndoDispatched}
            >
              <div className="button-2__icon">
                <i className="icon-approve"></i>
              </div>
              <span className="button-2__text">{t("Undo Approval")}</span>
            </button>
          )}

          {warrantyData.status === WarrantyStatus.WAITING && (
            <button
              className="button-2 button-2--rejected"
              type="button"
              onClick={handleWarrantyRefused}
            >
              <div className="button-2__icon">
                <i className="icon-refuse"></i>
              </div>
              <span className="button-2__text">{t("Decline")}</span>
            </button>
          )}

          {warrantyData.status === WarrantyStatus.REFUSED && (
            <button
              className="button-2 button-2--undo"
              type="button"
              onClick={handleWarrantyUndoRefused}
            >
              <div className="button-2__icon">
                <i className="icon-refuse"></i>
              </div>
              <span className="button-2__text">{t("Undo Decline")}</span>
            </button>
          )}
        </div>
        <div className="flex justify-center">
          <Link to="/warranty/list" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShowWarranty;
