import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ReturnCondition,
  ReturnModel,
  ReturnStatus,
} from "../../Api/Models/return.model";
import { LaravelValidationError } from "../../Api/request";
import { returnService } from "../../Api/Services/ReturnService";
import LongTextDisplay from "../../Components/LongTextDisplay";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";
import ReturnItemConditionSelector from "./ReturnItemConditionSelector";
import ReturnItemStatusSelector from "./ReturnItemStatusSelector";

interface ShowReturnProps {}

const ShowReturn: React.FunctionComponent<ShowReturnProps> = () => {
  const { startLoading, finnishLoading } = useLoading();

  let { returnId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showInfo } = useInfoModal();
  const { t } = useTranslation();

  const [returnData, setReturnData] = useState<ReturnModel>(state?.returnData);

  const [selectedReturnItemStatus, setSelectedReturnItemStatus] = useState<
    number | null
  >(null);

  const [selectedReturnItemCondition, setSelectedReturnItemCondition] =
    useState<number | null>(null);

  useEffect(() => {
    if (!returnData) {
      if (!returnId) {
        showInfo(t("Invalid QR code!"));
        navigate("/menu");
        return;
      }

      startLoading("get-return");

      returnService
        .getReturn(parseInt(returnId))
        .then((returnData) => {
          setReturnData(returnData);
        })
        .catch((e) => {
          showInfo(t("Invalid QR code!"));
          navigate("/menu");
        })
        .finally(() => {
          finnishLoading("get-return");
        });
    }
    //eslint-disable-next-line
  }, []);

  const getItemStatusColor = (itemStatus: ReturnStatus): string => {
    switch (itemStatus) {
      case ReturnStatus.GOOD:
        return "button-product-status-returned";
      case ReturnStatus.MISSING:
        return "button-product-status-missed";
      case ReturnStatus.UNUSABLE:
        return "button-product-status-not-reusable";

      default:
        return "button-product-status-default";
    }
  };

  const getItemStatusContent = (itemStatus: ReturnStatus): JSX.Element => {
    switch (itemStatus) {
      case ReturnStatus.GOOD:
        return <i className="icon-approve"></i>;
      case ReturnStatus.MISSING:
        return <i className="icon-refuse"></i>;
      case ReturnStatus.UNUSABLE:
        return <i className="icon-exclamation"></i>;
      default:
        return <></>;
    }
  };

  const handleSave = () => {
    if (returnData.items.some((x) => x.status === 1)) {
      showInfo(t("Please select a status for all products!"));
      return;
    }

    startLoading("update-return-data");
    let formData = new FormData();
    formData.append("return_id", returnData.id.toString());

    returnData.items.forEach((item, index) => {
      formData.append(`items[${index}][id]`, item.id.toString());
      formData.append(`items[${index}][status]`, item.status.toString());

      if (item.status === ReturnStatus.UNUSABLE) {
        formData.append(
          `items[${index}][condition]`,
          item.condition.toString()
        );
        if (item.note) {
          formData.append(`items[${index}][note]`, item.note);
        }
        if (item?.uploadImages) {
          for (var i = 0; i < item.uploadImages.length; i++) {
            formData.append(
              `items[${index}][uploadImages][]`,
              item.uploadImages[i]
            );
          }
        }
      }
    });

    returnService
      .update(formData)
      .then((updatedReturnData) => {
        console.log(updatedReturnData);

        setReturnData(updatedReturnData);
        showInfo("Gear return updated!");
        //navigate("/return/list");
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("update-return-data");
      });
  };

  const updateReturnItemStatus = (itemId: number, status: ReturnStatus) => {
    const itemsState = returnData.items;

    let itemToUpdate = itemsState.find((x) => x.id === itemId);

    if (itemToUpdate && status !== itemToUpdate.status) {
      itemToUpdate.status = status;

      setReturnData({ ...returnData, items: itemsState });

      if (status === ReturnStatus.UNUSABLE) {
        setSelectedReturnItemCondition(itemId);
      }
    }
    setSelectedReturnItemStatus(null);
  };

  const updateReturnItemCondition = (
    itemId: number,
    condition: ReturnCondition,
    images: FileList,
    note: string | null
  ) => {
    const itemsState = returnData.items;

    let itemToUpdate = itemsState.find((x) => x.id === itemId);

    if (itemToUpdate) {
      itemToUpdate.condition = condition;
      itemToUpdate.note = note;
      itemToUpdate.uploadImages = images;
      console.log(itemToUpdate);

      setReturnData({ ...returnData, items: itemsState });
    }
    setSelectedReturnItemCondition(null);
  };

  const cancelReturnItemConditionUpdate = () => {
    const itemsState = returnData.items;

    let itemToUpdate = itemsState.find(
      (x) => x.id === selectedReturnItemCondition
    );

    if (itemToUpdate) {
      itemToUpdate.status = ReturnStatus.UNKNOWN;
      setReturnData({ ...returnData, items: itemsState });
    }

    setSelectedReturnItemCondition(null);
  };

  if (!returnData) {
    return (
      <div className="view-container background-3">
        <div className="view-content">
          <div className="heading heading--medium text-center">
            {t("Gear Return Checklist")}
          </div>
        </div>
      </div>
    );
  }

  if (selectedReturnItemCondition) {
    return (
      <ReturnItemConditionSelector
        returnItemId={selectedReturnItemCondition}
        setNewCondition={updateReturnItemCondition}
        returnData={returnData}
        cancel={cancelReturnItemConditionUpdate}
      />
    );
  }

  return (
    <>
      <ReturnItemStatusSelector
        returnItemId={selectedReturnItemStatus}
        setNewStatus={updateReturnItemStatus}
        setSelectedReturnItem={setSelectedReturnItemStatus}
      />
      <div className="view-container background-3">
        <div className="view-content">
          <div className="heading heading--medium text-center">
            {t("Gear Return Checklist")}
          </div>
          <div className="content-card mb-8">
            <table className="info-table">
              <tbody>
                <tr>
                  <th>{t("Full Name")}:</th>
                  <td>
                    {returnData.firstName} {returnData.lastName}
                  </td>
                </tr>
                <tr>
                  <th>{t("Rider ID")}:</th>
                  <td>{returnData.riderId}</td>
                </tr>
                <tr>
                  <th>{t("Email")}:</th>
                  <td>
                    <LongTextDisplay text={returnData.email} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-list mb-8">
            {returnData.items.map((item) => (
              <div className="gear-return-list-row" key={item.id}>
                <button
                  className={getItemStatusColor(item.status)}
                  onClick={() => setSelectedReturnItemStatus(item.id)}
                >
                  {getItemStatusContent(item.status)}
                </button>
                <div className="gear-return-list-card ml-6">
                  <div className="gear-return-list-card__content">
                    <div className="gear-return-list-card__title">
                      {item.productName}
                    </div>
                  </div>
                  <div className="gear-return-list-card__right">
                    <div className="gear-return-list-card__pc">
                      {item.quantity} {t("pc")}
                    </div>
                    <i className="gear-return-list-card__icon icon-eye"></i>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-between">
            <Link to="/return/list" className="button">
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </Link>
            <button className="button button--green" onClick={handleSave}>
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowReturn;
