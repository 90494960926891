import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { OrderModel, OrderStatus } from "../../../Api/Models/order.model";
import { LaravelValidationError } from "../../../Api/request";
import { orderService } from "../../../Api/Services/OrderService";
import { stockService } from "../../../Api/Services/StockService";
import ImageInput from "../../../Components/ImageInput";
import LongTextDisplay from "../../../Components/LongTextDisplay";
import { useInfoModal } from "../../../Context/InfoModalProvider";
import { useLoading } from "../../../Context/LoadingProvider";

interface ShowStockOrderProps {}

const ShowStockOrder: React.FunctionComponent<ShowStockOrderProps> = () => {
  const { startLoading, finnishLoading } = useLoading();

  let { orderId } = useParams();
  const navigate = useNavigate();

  const { showInfo } = useInfoModal();
  const [orderData, setOrderData] = useState<OrderModel>();
  const { t } = useTranslation();

  const [images, setImages] = useState<File[]>([]);

  useEffect(() => {
    if (!orderData) {
      if (!orderId) {
        navigate("/stock/orders-list");
        return;
      }
      startLoading("get-order");
      orderService
        .getOrder(parseInt(orderId))
        .then((orderDataModel) => {
          setOrderData(orderDataModel);
        })
        .catch((e) => {
          navigate("/stock/orders-list");
        })
        .finally(() => {
          finnishLoading("get-order");
        });
    }
    //eslint-disable-next-line
  }, []);

  const handleOrderDispatched = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (orderData) {
      startLoading("set-order-dispatched");
      stockService
        .dispatch(orderData.id, images)
        .then((newOrderData) => {
          setOrderData(newOrderData);
          showInfo(t("Order successfully picked up!"));
          //navigate("/orders/list");
        })
        .catch((error: LaravelValidationError) => {
          if (error.errors?.errorMessage) {
            showInfo(error.errors?.errorMessage);
          }
        })
        .finally(() => {
          finnishLoading("set-order-dispatched");
        });
    }
  };

  if (!orderData) {
    return (
      <div className="view-container background-3">
        <div className="view-content">
          <div className="heading text-center">{t("Gear pick up")}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="view-container background-2">
      <div className="view-content">
        <div className="heading text-center">{t("Gear pick up")}</div>
        <div className="content-card mb-8">
          <table className="info-table">
            <tbody>
              <tr>
                <th>{t("Full Name")}:</th>
                <td>
                  {orderData.firstName} {orderData.lastName}
                </td>
              </tr>
              <tr>
                <th>{t("Rider ID")}:</th>
                <td>{orderData.riderId}</td>
              </tr>
              <tr>
                <th>{t("Email")}:</th>
                <td>
                  <LongTextDisplay text={orderData.email} />
                </td>
              </tr>
              <tr>
                <th className="lighter">{t("Product Name")}</th>
                <th className="lighter">#</th>
              </tr>
              {orderData.products.map((product, index) => (
                <tr className="tr-product" key={index}>
                  <th>{product.name}</th>
                  <td>
                    {product.quantity} {t("")}pc
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center gap-3 mb-4 items-center">
          {orderData.status !== OrderStatus.DISPATCHED && (
            <form
              onSubmit={handleOrderDispatched}
              className="flex flex-col items-center"
            >
              <ImageInput images={images} setImages={setImages} />

              <button type="submit" className="button-2 button-2--approved">
                <div className="button-2__icon">
                  <i className="icon-approve"></i>
                </div>
                <span className="button-2__text">{t("Approve")}</span>
              </button>
            </form>
          )}
        </div>
        <div className="flex justify-center">
          <Link to="/stock/orders/list" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShowStockOrder;
