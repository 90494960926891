import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GoalModel } from "../../Api/Models/goal.model";
import { SanitizationModel } from "../../Api/Models/sanitization.model";
import { sanitizationService } from "../../Api/Services/SanitizationService";
import { useLoading } from "../../Context/LoadingProvider";
import ShowSanitization from "./ShowSanitization";

interface SanitizationListProps {}

const SanitizationList: React.FunctionComponent<SanitizationListProps> = () => {
  const { t } = useTranslation();

  const [goal, setGoal] = useState<GoalModel>();
  const { startLoading, finnishLoading } = useLoading();
  const [sanitizations, setSanitizations] = useState<SanitizationModel[]>([]);
  const [currentSanitizazion, setCurrentSanitizazion] =
    useState<SanitizationModel | null>(null);

  useEffect(() => {
    loadSanitizationList();
    loadGoal();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentSanitizazion) {
      loadSanitizationList();
      loadGoal();
    }
    //eslint-disable-next-line
  }, [currentSanitizazion]);

  const loadSanitizationList = () => {
    startLoading("get-sanitization-list");
    sanitizationService
      .listSanitizations()
      .then((sanitizations) => {
        setSanitizations(sanitizations);
      })
      .finally(() => {
        finnishLoading("get-sanitization-list");
      });
  };

  const loadGoal = () => {
    sanitizationService.getGoal().then((dailyGoal) => {
      setGoal(dailyGoal);
    });
  };

  const getProducts = (): number[] => {
    let products: number[] = [];
    sanitizations.forEach((sanitization) => {
      if (!products.includes(sanitization.productId)) {
        products.push(sanitization.productId);
      }
    });
    return products;
  };

  if (currentSanitizazion) {
    return (
      <ShowSanitization
        sanitization={currentSanitizazion}
        setCurrentSanitizazion={setCurrentSanitizazion}
      />
    );
  }

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="heading text-center">{t("Sanitization")}</div>
        <div className="limited-width-content">
          <div className="flex justify-center">
            <div className="card card--magenta mb-4 ml-6 mr-6">
              <div className="text-center mb-4">
                <strong>
                  <u>{t("Daily Goal")}</u>
                </strong>
              </div>
              <div className="text-center">
                <b>
                  {goal?.today ?? 0}/{goal?.goal ?? 0}
                </b>
              </div>
            </div>
          </div>
        </div>

        <div className="card-list mb-12 limited-height-content">
          {getProducts().map((productId) => (
            <div className="card card--smaller-padding" key={productId}>
              <div className="flex justify-between gap-3">
                <div className="flex items-center gap-2">
                  {sanitizations.find((x) => x.productId === productId)
                    ?.productImage && (
                    <img
                      src={
                        sanitizations.find((x) => x.productId === productId)
                          ?.productImage ?? ""
                      }
                      className="image image--w35"
                      alt="Product"
                    />
                  )}

                  <div className="card__product-title">
                    {
                      sanitizations.find((x) => x.productId === productId)
                        ?.productName
                    }
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <div className="flex items-center">
                    <strong className="text-blue">
                      {
                        sanitizations
                          .filter((x) => x.productId === productId)
                          .filter((x) => x.sanitizedAt).length
                      }
                      /
                      {
                        sanitizations.filter((x) => x.productId === productId)
                          .length
                      }{" "}
                      <span className="text-small">{t("sanitized")}</span>
                    </strong>
                  </div>
                  {sanitizations
                    .filter((x) => x.productId === productId)
                    .filter((x) => x.sanitizedAt).length <
                    sanitizations.filter((x) => x.productId === productId)
                      .length && (
                    <button
                      type="button"
                      className="button-add"
                      onClick={() => {
                        const toSanitize = sanitizations
                          .filter((x) => !x.sanitizedAt)
                          .filter((x) => x.productId === productId);

                        if (toSanitize.length > 0) {
                          setCurrentSanitizazion(toSanitize[0]);
                        }
                      }}
                    >
                      <i className="icon-add"></i>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-4">
          <Link to="/menu" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SanitizationList;
