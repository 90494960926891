import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReturnCondition } from "../../Api/Models/return.model";
import { LaravelValidationError } from "../../Api/request";
import { sanitizationService } from "../../Api/Services/SanitizationService";
import ImageInput from "../../Components/ImageInput";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";

interface ThrowAwayProps {
  sanitizationId: number;
  cancel: CallableFunction;
  deleteFinnished: CallableFunction;
}

const ThrowAway: React.FunctionComponent<ThrowAwayProps> = ({
  sanitizationId,
  cancel,
  deleteFinnished,
}) => {
  const { t } = useTranslation();

  const conditionRef = useRef<ReturnCondition>(ReturnCondition.BROKEN);
  const noteRef = useRef<HTMLTextAreaElement | null>(null);

  const [images, setImages] = useState<File[]>([]);

  const { startLoading, finnishLoading } = useLoading();
  const { showInfo } = useInfoModal();

  const onConditionCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && e.target.value) {
      conditionRef.current = +e.target.value;
    }
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    startLoading("delete-sanitization");

    sanitizationService
      .throwAway(
        sanitizationId,
        noteRef.current?.value ?? "",
        conditionRef.current,
        images
      )
      .then(() => {
        deleteFinnished();
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("delete-sanitization");
      });
  };

  return (
    <form onSubmit={handleSave}>
      <div className="view-container background-3">
        <div className="view-content">
          <div className="heading text-center">{t("Product state")}</div>

          <div className="flex gap-2 mb-2 flex-wrap justify-center">
            <input
              type="radio"
              id="manufacturing_fault"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.MANUFACTURING_FAULT}
              required
            />
            <label htmlFor="manufacturing_fault">
              {t("Manufacturing faultiness")}
            </label>

            <input
              type="radio"
              id="overused"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.OVERUSED}
              required
            />
            <label htmlFor="overused">{t("Overused")}</label>

            <input
              type="radio"
              id="broken"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.BROKEN}
              required
            />
            <label htmlFor="broken">{t("Broken")}</label>

            <input
              type="radio"
              id="stain"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.STAIN}
              required
            />
            <label htmlFor="stain">{t("Stain")}</label>
          </div>

          <ImageInput images={images} setImages={setImages} />

          <textarea
            className="mb-8"
            placeholder="Please write your note here!"
            ref={noteRef}
          ></textarea>

          <div className="flex justify-between">
            <button className="button" onClick={() => cancel()}>
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </button>
            <button type="submit" className="button button--green">
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ThrowAway;
