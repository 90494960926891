import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GoalModel } from "../../Api/Models/goal.model";
import { OrderModel, OrderStatus } from "../../Api/Models/order.model";
import { orderService } from "../../Api/Services/OrderService";
import { useLoading } from "../../Context/LoadingProvider";

interface OrderListProps {}

const OrderList: React.FunctionComponent<OrderListProps> = () => {
  const [orders, setOrders] = useState<OrderModel[]>([]);
  const [goal, setGoal] = useState<GoalModel>();
  const { startLoading, finnishLoading } = useLoading();
  const [selectedFilter, setSelectedFiler] = useState<number>(-1);
  const [searchText, setSearchText] = useState<string>("");

  const { t } = useTranslation();

  useEffect(() => {
    startLoading("get-order-list");
    orderService
      .listOrders()
      .then((orders) => {
        console.log(orders);

        setOrders(orders);
      })
      .finally(() => {
        finnishLoading("get-order-list");
      });

    orderService.getGoal().then((dailyGoal) => {
      setGoal(dailyGoal);
    });
    //eslint-disable-next-line
  }, []);

  const getOrderStatusText = (orderStatus: OrderStatus): string => {
    switch (orderStatus) {
      case OrderStatus.WAITING:
        return t("pending");

      case OrderStatus.DISPATCHED:
        return t("dispatched");

      case OrderStatus.REFUSED:
        return t("declined");
      default:
        return "";
    }
  };

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="heading text-center">{t("Order list")}</div>
        <div className="flex justify-center">
          <div className="pc-bubble mb-4">
            {
              orders.filter(
                (order) =>
                  selectedFilter === -1 || order.status === selectedFilter
              ).length
            }
            &nbsp;pc.
          </div>
        </div>
        <div className="limited-width-content">
          <div className="flex justify-center">
            <div className="card card--magenta mb-6 ml-6 mr-6">
              <div className="text-center mb-4">
                <strong>
                  <u>{t("Daily Goal")}</u>
                </strong>
              </div>
              <div className="text-center">
                <b>
                  {goal?.today ?? 0}/{goal?.goal ?? 0}
                </b>
              </div>
            </div>
          </div>

          <div className="flex justify-between mb-2 items-center gap-2">
            <input
              type="text"
              placeholder={t("Search...").toString()}
              className="input-field"
              onChange={(e) => setSearchText(e.target.value)}
            />
            <div className="select-minimal-wrapper mr-2">
              <select
                dir="rtl"
                onChange={(e) => setSelectedFiler(parseInt(e.target.value))}
              >
                <option value={-1}>{t("All")}</option>
                <option value={1}>{t("Pending")}</option>
                <option value={3}>{t("Dispatched")}</option>
                <option value={2}>{t("Declined")}</option>
              </select>
            </div>
          </div>
          <div className="card-list mb-12 limited-height-content">
            {orders
              .filter(
                (order) =>
                  selectedFilter === -1 || order.status === selectedFilter
              )
              .filter(
                (order) =>
                  searchText === "" ||
                  (order.firstName + " " + order.lastName)
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  order.number.toString().includes(searchText)
              )
              .map((order) => (
                <Link
                  to={`/orders/${order.id}`}
                  state={{ orderData: order }}
                  className="order-list-card"
                  key={order.id}
                >
                  <div className="flex">
                    <div className="order-list-card__left">
                      <div className="order-list-card__name">
                        {order.firstName} {order.lastName}
                      </div>
                      <div className="order-list-card__info">
                        <strong>{t("Order date")}:</strong> {order.orderDate}
                        <br />
                        {order.products
                          .map((product) => product.name)
                          .join(", ")}
                      </div>
                    </div>

                    <div className="order-list-card__right">
                      <div
                        className={
                          "order-list-card__status " +
                          getOrderStatusText(order.status)
                        }
                      ></div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    {getOrderStatusText(order.status)}
                  </div>
                </Link>
              ))}
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <Link to="/menu" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderList;
