import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GoalModel } from "../../Api/Models/goal.model";
import { ReturnModel } from "../../Api/Models/return.model";
import { returnService } from "../../Api/Services/ReturnService";
import { useLoading } from "../../Context/LoadingProvider";

interface ReturnListProps {}

const ReturnList: React.FunctionComponent<ReturnListProps> = () => {
  const [returns, setReturns] = useState<ReturnModel[]>([]);
  const [goal, setGoal] = useState<GoalModel>();
  const { startLoading, finnishLoading } = useLoading();
  //const [selectedFilter, setSelectedFiler] = useState<number>(-1);
  const { t } = useTranslation();

  useEffect(() => {
    startLoading("get-return-list");
    returnService
      .listReturns()
      .then((returns) => {
        setReturns(returns);
      })
      .finally(() => {
        finnishLoading("get-return-list");
      });

    returnService.getGoal().then((dailyGoal) => {
      setGoal(dailyGoal);
    });

    //eslint-disable-next-line
  }, []);

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="heading text-center">{t("Return list")}</div>
        <div className="flex justify-center">
          <div className="pc-bubble mb-4">
            {returns.length} {t("pc")}.
          </div>
        </div>
        <div className="limited-width-content">
          <div className="flex justify-center">
            <div className="card card--magenta mb-4 ml-6 mr-6">
              <div className="text-center mb-4">
                <strong>
                  <u>{t("Daily Goal")}</u>
                </strong>
              </div>
              <div className="text-center">
                <b>
                  {goal?.today ?? 0}/{goal?.goal ?? 0}
                </b>
              </div>
            </div>
          </div>

          {/*
          <div className="flex justify-end mb-2">
            <div className="select-minimal-wrapper mr-2">
              <select dir="rtl">
                <option value="">All</option>
                <option value="lorem">lorem</option>
                <option value="ipsum">ipsum</option>
                <option value="dolor">dolor</option>
              </select>
            </div>
          </div>
            */}
          <div className="card-list mb-12 limited-height-content">
            {returns
              //.filter((i) => selectedFilter === -1 || i.status === selectedFilter)
              .map((item) => (
                <Link
                  to={`/return/${item.id}`}
                  state={{ returnData: item }}
                  className="order-list-card"
                  key={item.id}
                >
                  <div className="flex">
                    <div className="order-list-card__left">
                      <div className="order-list-card__name">
                        {item.firstName} {item.lastName}
                      </div>
                      <div className="order-list-card__info">
                        <strong>{t("Order date")}:</strong> {item.date}
                        <br />
                        {item.items
                          .map((product) => product.productName)
                          .join(", ")}
                      </div>
                    </div>

                    <div className="order-list-card__right"></div>
                  </div>
                  {/*<div className="flex justify-end">
                      <div className="order-list-card__status approved">
                        approved
                      </div>
                          </div>*/}
                </Link>
              ))}
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <Link to="/menu" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ReturnList;
