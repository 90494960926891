import { useTranslation } from "react-i18next";

interface ImageInputProps {
  images: File[];
  setImages: CallableFunction;
}

const ImageInput: React.FunctionComponent<ImageInputProps> = ({
  images,
  setImages,
}) => {
  const { t } = useTranslation();

  const removeImage = (index: number) => {
    let newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  return (
    <>
      <div className="flex justify-center mb-8">
        <input
          type="file"
          id="take-a-picture"
          className="file-input"
          multiple
          accept="image/*"
          onChange={(e) => {
            if (e.target.files) {
              setImages(Array.from(e.target.files));
            }
          }}
          required
        />
        <label htmlFor="take-a-picture">
          {t("Take a picture")}
          <i className="icon-camera"></i>
        </label>
      </div>

      <div className="row mb-3">
        {Array.from(images)?.map((image, index) => (
          <div
            className="flex justify-center mt-6 ml-8 mr-8 relative"
            key={index}
            onClick={() => removeImage(index)}
          >
            <div className="picture-preview">
              <button className="button--delete" type="button">
                &times;
              </button>
              <img src={URL.createObjectURL(image)} alt="Selected file" />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ImageInput;
