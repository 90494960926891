import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GoalModel } from "../../Api/Models/goal.model";
import NewKitInstructions from "./NewKitInstructions";
import { useLoading } from "../../Context/LoadingProvider";
import { kitService } from "../../Api/Services/KitService";
import { KitModel, PrepareProductModel } from "../../Api/Models/product.model";
import KitIcon from "../../Assets/gfx/kit_icon.png";

interface NewKitListProps {}

const NewKitList: React.FunctionComponent<NewKitListProps> = () => {
  const [goal, setGoal] = useState<GoalModel>({ today: 0, goal: 45 });
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();

  const [creating, setCreating] = useState<KitModel | null>(null);
  const [kits, setKits] = useState<KitModel[]>([]);
  const [products, setProducts] = useState<PrepareProductModel[]>([]);

  useEffect(() => {
    startLoading("get-kit-list");
    kitService
      .listKits()
      .then((kits) => {
        setKits(kits);
      })
      .finally(() => {
        finnishLoading("get-kit-list");
      });

    startLoading("get-products-list");
    kitService
      .listProducts()
      .then((produts) => {
        setProducts(produts);
      })
      .finally(() => {
        finnishLoading("get-products-list");
      });

    kitService.getGoal().then((dailyGoal) => {
      setGoal(dailyGoal);
    });
    //eslint-disable-next-line
  }, []);

  const cancelCreate = () => {
    if (goal.today > 0) {
      setGoal({ ...goal, today: goal.today - 1 });
    }
    setCreating(null);
  };

  const finnishCreate = () => {
    if (creating) {
      let kitsState = [...kits];
      kitsState = kitsState.map((kit) => {
        if (kit.id === creating.id) {
          const newCount = kit.dailyCount + 1;
          return {
            ...kit,
            dailyCount: newCount,
            available: kit.available + 1,
          };
        }
        return kit;
      });

      setKits(kitsState);
      increaseGoalToday();
    }
    setCreating(null);
  };

  const increaseGoalToday = (count: number = 1) => {
    let goalState = { ...goal };
    goalState.today += count;
    setGoal(goalState);
  };

  const disassemble = (kit: KitModel) => {
    startLoading("disassemble-kit");
    kitService
      .disassembleKit(kit)
      .then(() => {
        let kitsState = [...kits];
        kitsState = kitsState.map((i) => {
          if (i.id === kit.id) {
            const newCount = i.dailyCount - 1;
            return {
              ...i,
              dailyCount: newCount,
              available: kit.available - 1,
            };
          }
          return i;
        });

        setKits(kitsState);
        increaseGoalToday(-1);
      })
      .finally(() => {
        finnishLoading("disassemble-kit");
      });
  };

  const prepareProduct = (product: PrepareProductModel) => {
    startLoading("prepare-products");
    kitService
      .prepareProduct(product)
      .then(() => {
        let productsState = [...products];
        productsState = productsState.map((i) => {
          if (
            i.productId === product.productId &&
            i.variationId === product.variationId
          ) {
            const newCount = i.dailyCount + 1;

            return {
              ...i,
              dailyCount: newCount,
              available: i.available + 1,
            };
          }
          return i;
        });
        setProducts(productsState);
        increaseGoalToday();
      })
      .finally(() => {
        finnishLoading("prepare-products");
      });
    //todo service call prepare
  };

  const disassembleProduct = (product: PrepareProductModel) => {
    startLoading("unprepare-products");
    kitService
      .disassembleProduct(product)
      .then(() => {
        let productsState = [...products];
        productsState = productsState.map((i) => {
          if (i.productId === product.productId) {
            const newCount = i.dailyCount - 1;
            return {
              ...i,
              dailyCount: newCount,
              available: i.available - 1,
            };
          }
          return i;
        });
        setProducts(productsState);
        increaseGoalToday(-1);
      })
      .finally(() => {
        finnishLoading("unprepare-products");
      });
    //todo service call unprepare
  };

  if (creating) {
    return (
      <NewKitInstructions
        kit={creating}
        cancel={cancelCreate}
        finnish={finnishCreate}
      />
    );
  }

  return (
    <div className="view-container background-2">
      <div className="view-content">
        <div className="heading text-center">{t("New Kits")}</div>

        <div className="limited-width-content">
          <div className="flex justify-center">
            <div className="card card--magenta mb-8 ml-6 mr-6">
              <div className="text-center mb-4">
                <strong>{t("Daily Goal")}</strong>
                <br />
                <strong>
                  <u>{t("Create New Kits")}</u>
                </strong>
              </div>
              <div className="text-center">
                <strong>
                  {goal.today}/{goal.goal}
                </strong>
              </div>
            </div>
          </div>
        </div>

        <div className="card-list mb-12 limited-height-content">
          {kits.map((kit) => (
            <div className="card" key={kit.id}>
              <div className="flex justify-between gap-3">
                <div className="flex items-center gap-3">
                  <img
                    src={KitIcon}
                    className="image image--w35"
                    alt="Product"
                  />
                  <div>
                    <div className="card__product-title">{kit.name}</div>
                    {kit.available && (
                      <div className="text-small">
                        {t("Available")}:&nbsp;
                        <strong className="text-green">{kit.available}</strong>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <strong className="text-blue">
                    {kit.dailyCount}/{kit.goal}
                  </strong>
                  <button
                    type="button"
                    className="button-add"
                    onClick={() => {
                      setCreating(kit);
                    }}
                  >
                    <i className="icon-add"></i>
                  </button>
                  <button
                    type="button"
                    className="button-remove"
                    onClick={() => {
                      disassemble(kit);
                    }}
                  >
                    <i className="icon-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}

          {products.map((product) => (
            <div
              className="card"
              key={product.productId + "-" + (product.variationId ?? "")}
            >
              <div className="flex justify-between gap-3">
                <div className="flex items-center gap-3">
                  <img
                    src={product.image}
                    className="image image--w35"
                    alt="Product"
                  />
                  <div>
                    <div className="card__product-title">{product.name}</div>
                    {product.available && (
                      <div className="text-small">
                        {t("Available")}:&nbsp;
                        <strong className="text-green">
                          {product.available}
                        </strong>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <strong className="text-blue">
                    {product.dailyCount}/{product.goal}
                  </strong>
                  <button
                    type="button"
                    className="button-add"
                    onClick={() => {
                      prepareProduct(product);
                    }}
                  >
                    <i className="icon-add"></i>
                  </button>
                  <button
                    type="button"
                    className="button-remove"
                    onClick={() => {
                      disassembleProduct(product);
                    }}
                  >
                    <i className="icon-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-4">
          <Link to="/menu" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewKitList;
