import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StockModel } from "../../Api/Models/stock.model";
import { LaravelValidationError } from "../../Api/request";
import {
  OrderDataInterface,
  stockService,
} from "../../Api/Services/StockService";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";

interface StockOrderProps {
  close: CallableFunction;
  stocks: StockModel[];
}

const StockOrder: React.FunctionComponent<StockOrderProps> = ({
  close,
  stocks,
}) => {
  const { startLoading, finnishLoading } = useLoading();
  const { showInfo } = useInfoModal();
  const noteRef = useRef<HTMLTextAreaElement | null>(null);

  const [orderData, setOrderData] = useState<OrderDataInterface[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const newOrderData: OrderDataInterface[] = [];

    stocks.forEach((stock) => {
      newOrderData.push({
        product_id: stock.productId,
        product_variation_id: stock.variationId,
        quantity: 0,
        checked: false,
      });
    });
    console.log(
      stocks.reduce((accumulator, object) => {
        return accumulator + object.maxAllowedQuantity;
      }, 0)
    );

    setOrderData(newOrderData);
    //eslint-disable-next-line
  }, stocks);

  const handleOrder = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(orderData);

    startLoading("order-stock");
    stockService
      .createOrder(
        orderData.filter((x) => x.checked),
        noteRef.current?.value ?? null
      )
      .then(() => {
        showInfo(t("Successful order!"));
      })
      .catch((error: LaravelValidationError) => {
        console.log(error, error.errors, error.errors?.errorMessage);

        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        } else if (error.message) {
          showInfo(error.message);
        }
      })
      .finally(() => {
        close();
        finnishLoading("order-stock");
      });
  };

  if (stocks.length === 0) {
    return null;
  }

  if (true) {
    return (
      <div className="view-container flex flex-col justify-between background-3">
        <div className="fake-for-layout"></div>
        <div className="view-content">
          <div className="heading text-center">{t("Top Up")}</div>
          <form onSubmit={handleOrder}>
            <div className="order-product-card mb-8">
              <div className="flex items-center mb-10">
                {stocks[0].productImage && (
                  <img
                    src={stocks[0].productImage}
                    alt="Product"
                    className="order-product__image mr-6"
                  />
                )}
                <div className="order-product__heading">
                  <div className="order-product__heading-title">
                    {stocks[0].productName}
                  </div>
                  <div className="order-product__heading-pcs">
                    {stocks.reduce((accumulator, object) => {
                      return accumulator + object.quantity;
                    }, 0)}
                    &nbsp;{t("pc")}
                  </div>
                </div>
              </div>

              <div className="order-product__variations">
                {stocks.map((stock) => (
                  <div
                    className="order-product-variation flex justify-between"
                    key={`${stock.productId}-${stock.variationId}`}
                  >
                    <label
                      className="checkbox"
                      htmlFor={`order-product-${stock.productId}-${stock.variationId}`}
                    >
                      <input
                        type="checkbox"
                        checked={
                          orderData.find(
                            (x) => x.product_variation_id === stock.variationId
                          )?.checked ?? false
                        }
                        onChange={(e) => {
                          let currentData = orderData.find(
                            (x) => x.product_variation_id === stock.variationId
                          );

                          if (!currentData) {
                            return;
                          }
                          let orderDataState = orderData;
                          orderDataState = orderDataState.filter(
                            (x) => x.product_variation_id !== stock.variationId
                          );

                          orderDataState.push({
                            product_id: currentData.product_id,
                            product_variation_id:
                              currentData.product_variation_id,
                            quantity: currentData.quantity,
                            checked: !!e.target.checked,
                          });
                          console.log(orderDataState);

                          setOrderData(orderDataState);
                        }}
                      />
                      <div className="checkbox__icon"></div>
                      <div className="flex items-center gap-3">
                        <strong>{stock.variationName}</strong>
                        <div className="order-product-variation__quantity-info">
                          {stock.quantity} {t("pcs")}
                        </div>
                      </div>
                    </label>
                    <div>
                      <div className="order-product-variation-quantity">
                        <button
                          type="button"
                          className="order-product-variation-quantity__decrement"
                          onClick={() => {
                            let currentData = orderData.find(
                              (x) =>
                                x.product_variation_id === stock.variationId
                            );

                            if (!currentData || currentData.quantity <= 0) {
                              return;
                            }

                            let orderDataState = orderData;
                            orderDataState = orderDataState.filter(
                              (x) =>
                                x.product_variation_id !== stock.variationId
                            );

                            orderDataState.push({
                              product_id: stock.productId,
                              product_variation_id: stock.variationId,
                              quantity: currentData.quantity - 1,
                              checked: currentData.quantity - 1 !== 0,
                            });

                            setOrderData(orderDataState);
                          }}
                        ></button>
                        <input
                          className="order-product-variation-quantity__input"
                          type="number"
                          value={
                            orderData.find(
                              (x) =>
                                x.product_variation_id === stock.variationId
                            )?.quantity ?? 0
                          }
                          disabled={true}
                          id={`order-product-${stock.productId}-${stock.variationId}`}
                        />
                        <button
                          type="button"
                          className="order-product-variation-quantity__increment"
                          onClick={() => {
                            let currentData = orderData.find(
                              (x) =>
                                x.product_variation_id === stock.variationId
                            );

                            if (!currentData) {
                              return;
                            }

                            let orderDataState = orderData;
                            orderDataState = orderDataState.filter(
                              (x) =>
                                x.product_variation_id !== stock.variationId
                            );

                            orderDataState.push({
                              product_id: stock.productId,
                              product_variation_id: stock.variationId,
                              quantity: currentData.quantity + 1,
                              checked: true,
                            });

                            setOrderData(orderDataState);
                          }}
                        ></button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {orderData.reduce((accumulator, object) => {
              return accumulator + object.quantity;
            }, 0) >
              stocks.reduce((accumulator, object) => {
                return accumulator + object.maxAllowedQuantity;
              }, 0) && (
              <>
                <p className="mb-6">
                  {t(
                    "Your requested quantity is more than the limit, please reason your request!"
                  )}
                </p>
                <textarea
                  className="mb-8"
                  placeholder={t("Message") ?? ""}
                  ref={noteRef}
                ></textarea>
              </>
            )}

            <div className="flex justify-center mb-8">
              <button className="button button--orange" type="submit">
                {t("Place an order")}
              </button>
            </div>
          </form>
        </div>

        <div className="flex justify-center mb-8">
          <button className="button" type="button" onClick={() => close()}>
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </button>
        </div>
      </div>
    );
  }
};

export default StockOrder;
