import { useTranslation } from "react-i18next";
import { ReturnStatus } from "../../Api/Models/return.model";

interface ReturnItemStatusSelectorProps {
  returnItemId: number | null;
  setNewStatus: CallableFunction;
  setSelectedReturnItem: CallableFunction;
}

const ReturnItemStatusSelector: React.FunctionComponent<
  ReturnItemStatusSelectorProps
> = ({ returnItemId, setNewStatus, setSelectedReturnItem }) => {
  const { t } = useTranslation();

  if (returnItemId === null) {
    return null;
  }

  return (
    <div className="modal" tabIndex={-1} aria-modal="true" role="dialog">
      <div className="modal__dialog">
        <div className="modal__header">
          <div className="modal__heading">{t("Please select...")}</div>
          <div
            className="modal__close"
            onClick={() => setSelectedReturnItem(null)}
          >
            ✖
          </div>
        </div>
        <div className="modal__body">
          <button
            className="flex items-center w-full mb-4"
            onClick={() => setNewStatus(returnItemId, ReturnStatus.GOOD)}
          >
            <div className="button-product-status-returned mr-3">
              <i className="icon-approve"></i>
            </div>
            <div>{t("Product successfully returned")}</div>
          </button>

          <button
            className="flex items-center w-full mb-4"
            onClick={() => setNewStatus(returnItemId, ReturnStatus.UNUSABLE)}
          >
            <div className="button-product-status-not-reusable mr-3">
              <i className="icon-exclamation"></i>
            </div>
            <div>{t("Product cannot be reused")}</div>
          </button>

          <button
            className="flex items-center w-full"
            onClick={() => setNewStatus(returnItemId, ReturnStatus.MISSING)}
          >
            <div className="button-product-status-missed mr-3">
              <i className="icon-refuse"></i>
            </div>
            <div>{t("Product missing")}</div>
          </button>
        </div>
        <div className="modal__footer">
          <div className="flex justify-center">
            <button className="button button--orange">{t("Cancel")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnItemStatusSelector;
