import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReturnCondition, ReturnModel } from "../../Api/Models/return.model";
import ImageInput from "../../Components/ImageInput";
import LongTextDisplay from "../../Components/LongTextDisplay";

interface ReturnItemConditionSelectorProps {
  returnItemId: number | null;
  setNewCondition: CallableFunction;
  returnData: ReturnModel;
  cancel: CallableFunction;
}

const ReturnItemConditionSelector: React.FunctionComponent<
  ReturnItemConditionSelectorProps
> = ({ returnItemId, setNewCondition, returnData, cancel }) => {
  const conditionRef = useRef<ReturnCondition>();
  const noteRef = useRef<HTMLTextAreaElement | null>(null);

  const [images, setImages] = useState<File[]>([]);

  const { t } = useTranslation();

  const onConditionCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && e.target.value) {
      conditionRef.current = +e.target.value;
    }
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setNewCondition(
      returnItemId,
      conditionRef.current,
      images,
      noteRef.current?.value
    );
  };

  if (!returnItemId) {
    return null;
  }

  return (
    <form onSubmit={handleSave}>
      <div className="view-container background-3">
        <div className="view-content">
          <div className="heading text-center">{t("Product state")}</div>
          <div className="content-card mb-8">
            <table className="info-table">
              <tbody>
                <tr>
                  <th>{t("Full Name")}:</th>
                  <td>
                    {returnData.firstName} {returnData.lastName}
                  </td>
                </tr>
                <tr>
                  <th>{t("Rider ID")}:</th>
                  <td>{returnData.riderId}</td>
                </tr>
                <tr>
                  <th>{t("Email")}:</th>
                  <td>
                    <LongTextDisplay text={returnData.email} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex gap-2 mb-2 flex-wrap justify-center">
            <input
              type="radio"
              id="manufacturing_fault"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.MANUFACTURING_FAULT}
              required
            />
            <label htmlFor="manufacturing_fault">
              {t("Manufacturing faultiness")}
            </label>

            <input
              type="radio"
              id="overused"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.OVERUSED}
              required
            />
            <label htmlFor="overused">{t("Overused")}</label>

            <input
              type="radio"
              id="broken"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.BROKEN}
              required
            />
            <label htmlFor="broken">{t("Broken")}</label>

            <input
              type="radio"
              id="stain"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.STAIN}
              required
            />
            <label htmlFor="stain">{t("Stain")}</label>
          </div>

          <ImageInput images={images} setImages={setImages} />

          <textarea
            className="mb-8"
            placeholder="Please write your note here!"
            ref={noteRef}
          ></textarea>

          <div className="flex justify-between">
            <button className="button" onClick={() => cancel()}>
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </button>
            <button type="submit" className="button button--green">
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ReturnItemConditionSelector;
