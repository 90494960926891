import { useTranslation } from "react-i18next";
import { OrderProduct } from "../../../Api/Models/order.model";

interface StandardProductProps {
  item: OrderProduct;
  setSanitizeProduct: CallableFunction;
  setExchangeProduct: CallableFunction;
}

const StandardProduct: React.FunctionComponent<StandardProductProps> = ({
  item,
  setSanitizeProduct,
  setExchangeProduct,
}) => {
  const { t } = useTranslation();

  return (
    <div className="card mb-2">
      <div className="flex items-center justify-between gap-4 mb-2">
        <div className="modify-gear-card__header">
          <img
            src={item.image ?? ""}
            className="modify-gear-card__image"
            alt="Product"
          />
          <div className="modify-gear-card__title">{item.name}</div>
        </div>
        {/*<div className="text-small font-semibold">
          {t("Status")}:&nbps;
          <span className="modify-gear-card__status-text text-orange">
            Sanitized
          </span>
        </div>*/}
      </div>
      <div className="flex gap-4 items-center justify-between">
        <div className="text-small">{t("Sanitization Possible?")}</div>
        <div className="flex gap-4">
          <label className="checkbox checkbox--reversed items-center">
            <input
              type="radio"
              name="sanitization_possible"
              checked={item.exchanged === false}
              onChange={() => setSanitizeProduct(item)}
            />
            <div className="checkbox__icon"></div>
            <div className="checkbox__text text-small">{t("Yes")}</div>
          </label>

          <label className="checkbox checkbox--reversed items-center">
            <input
              type="radio"
              name="sanitization_possible"
              checked={item.exchanged === true}
              onChange={() => setExchangeProduct(item)}
            />
            <div className="checkbox__icon"></div>
            <div className="checkbox__text text-small">{t("No")}</div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default StandardProduct;
