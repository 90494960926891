import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { OrderModel, OrderStatus } from "../../Api/Models/order.model";
import { ProductModel } from "../../Api/Models/product.model";
import { LaravelValidationError } from "../../Api/request";
import { orderService } from "../../Api/Services/OrderService";
import LongTextDisplay from "../../Components/LongTextDisplay";
import { useInfoModal } from "../../Context/InfoModalProvider";
import { useLoading } from "../../Context/LoadingProvider";
import ModifyOrder from "./ModifyOrder/ModifyOrder";
interface ShowOrderProps {}

const ShowOrder: React.FunctionComponent<ShowOrderProps> = () => {
  const { startLoading, finnishLoading } = useLoading();

  let { orderId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { showInfo } = useInfoModal();
  const [orderData, setOrderData] = useState<OrderModel>(state?.orderData);
  const [modifing, setModifing] = useState<boolean>(false);

  const productsRef = useRef<ProductModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>(productsRef.current);

  const { t } = useTranslation();

  useEffect(() => {
    if (!orderData) {
      if (!orderId) {
        showInfo(t("Invalid QR code"));
        navigate("/menu");
        return;
      }

      startLoading("get-order");

      orderService
        .getOrder(parseInt(orderId))
        .then((orderDataModel) => {
          setOrderData(orderDataModel);
        })
        .catch((e) => {
          showInfo(t("Invalid QR code"));
          navigate("/menu");
        })
        .finally(() => {
          finnishLoading("get-order");
        });
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orderData && products.length === 0) {
      orderData.products.forEach((product) => {
        if (product.variationId && product.productId) {
          startLoading("product-get-variations-" + product.productId);
          orderService
            .getProductVatiations(product.productId)
            .then((productModel) => {
              productsRef.current.push(productModel);
              setProducts(productsRef.current);
            })
            .finally(() => {
              finnishLoading("product-get-variations-" + product.productId);
            });
        }
      });
    }
    //eslint-disable-next-line
  }, [orderData]);

  const handleOrderDispatched = () => {
    startLoading("set-order-dispatched");

    orderService
      .dispatch(orderData.id)
      .then((newOrderData) => {
        setOrderData(newOrderData);
        showInfo(t("Order successfully picked up!"));
        //navigate("/orders/list");
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("set-order-dispatched");
      });
  };

  const handleOrderUndoDispatched = () => {
    startLoading("undo-order-dispatched");

    orderService
      .undoDispatch(orderData.id)
      .then((newOrderData) => {
        setOrderData(newOrderData);
        showInfo(t("Order dispatched undone!"));
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("undo-order-dispatched");
      });
  };

  const handleOrderRefused = () => {
    startLoading("set-order-refused");

    orderService
      .refuse(orderData.id)
      .then((newOrderData) => {
        setOrderData(newOrderData);
        showInfo(t("Order declined!"));

        //navigate("/orders/list");
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("set-order-refused");
      });
  };

  const handleOrderUndoRefused = () => {
    startLoading("undo-order-refused");

    orderService
      .undoRefuse(orderData.id)
      .then((newOrderData) => {
        setOrderData(newOrderData);
        showInfo(t("Gear pick up status updated!"));
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("undo-order-refused");
      });
  };

  const cancelModifing = () => {
    setModifing(false);
  };

  if (!orderData) {
    return (
      <div className="view-container background-3">
        <div className="view-content">
          <div className="heading text-center">{t("Gear pick up")}</div>
        </div>
      </div>
    );
  }

  if (modifing) {
    return (
      <ModifyOrder
        order={orderData}
        cancel={cancelModifing}
        products={products}
        setOrder={setOrderData}
      />
    );
  }

  return (
    <div className="view-container background-2">
      <div className="view-content">
        <div className="heading text-center">{t("Gear pick up")}</div>
        <div className="content-card mb-8">
          <table className="info-table">
            <tbody>
              <tr>
                <th>{t("Full Name")}:</th>
                <td>
                  {orderData.firstName} {orderData.lastName}
                </td>
              </tr>
              <tr>
                <th>{t("Rider ID")}:</th>
                <td>{orderData.riderId}</td>
              </tr>
              <tr>
                <th>{t("Email")}:</th>
                <td>
                  <LongTextDisplay text={orderData.email} />
                </td>
              </tr>
              <tr>
                <th className="lighter">{t("Product Name")}</th>
                <th className="lighter">#</th>
              </tr>
              {orderData.products.map((product, index) => (
                <tr className="tr-product" key={index}>
                  <th>{product.name}</th>
                  <td>
                    {product.quantity} {t("")}pc
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center gap-3 mb-4">
          {orderData.status !== OrderStatus.DISPATCHED && (
            <button
              type="button"
              className="button-2 button-2--approved"
              onClick={handleOrderDispatched}
            >
              <div className="button-2__icon">
                <i className="icon-approve"></i>
              </div>
              <span className="button-2__text">{t("Approve")}</span>
            </button>
          )}

          {orderData.status === OrderStatus.DISPATCHED && (
            <button
              type="button"
              className="button-2 button-2--undo"
              onClick={handleOrderUndoDispatched}
            >
              <div className="button-2__icon">
                <i className="icon-approve"></i>
              </div>
              <span className="button-2__text">{t("Undo Approval")}</span>
            </button>
          )}

          {
            <button
              type="button"
              className="button-2 button-2--modify"
              onClick={() => setModifing(true)}
            >
              <div className="button-2__icon">
                <i className="icon-question"></i>
              </div>
              <span className="button-2__text">{t("Modify")}</span>
            </button>
          }

          {orderData.status === OrderStatus.WAITING && (
            <button
              className="button-2 button-2--rejected"
              type="button"
              onClick={handleOrderRefused}
            >
              <div className="button-2__icon">
                <i className="icon-refuse"></i>
              </div>
              <span className="button-2__text">{t("Decline")}</span>
            </button>
          )}

          {orderData.status === OrderStatus.REFUSED && (
            <button
              className="button-2 button-2--undo"
              type="button"
              onClick={handleOrderUndoRefused}
            >
              <div className="button-2__icon">
                <i className="icon-refuse"></i>
              </div>
              <span className="button-2__text">{t("Undo Declined")}</span>
            </button>
          )}
        </div>
        <div className="flex justify-center">
          <Link to="/orders/list" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShowOrder;
