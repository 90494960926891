import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderModel, OrderProduct } from "../../../Api/Models/order.model";
import { ReturnCondition } from "../../../Api/Models/return.model";
import { LaravelValidationError } from "../../../Api/request";
import { orderService } from "../../../Api/Services/OrderService";
import ImageInput from "../../../Components/ImageInput";
import LongTextDisplay from "../../../Components/LongTextDisplay";
import { useInfoModal } from "../../../Context/InfoModalProvider";
import { useLoading } from "../../../Context/LoadingProvider";

interface ItemConditionProps {
  order: OrderModel;
  orderItem: OrderProduct;
  cancel: CallableFunction;
  finnish: CallableFunction;
}

const ItemCondition: React.FunctionComponent<ItemConditionProps> = ({
  order,
  orderItem,
  cancel,
  finnish,
}) => {
  const conditionRef = useRef<ReturnCondition>();
  const noteRef = useRef<HTMLTextAreaElement | null>(null);

  const [images, setImages] = useState<File[]>([]);

  const { showInfo } = useInfoModal();
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();

  const onConditionCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked && e.target.value) {
      conditionRef.current = +e.target.value;
    }
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!conditionRef.current) {
      return;
    }

    startLoading("exchange-order-item");
    let formData = new FormData();
    formData.append("order_id", order.id.toString());
    formData.append("order_item_id", orderItem.id.toString());

    formData.append(`condition`, conditionRef.current.toString());
    if (noteRef.current?.value) {
      formData.append(`note`, noteRef.current?.value);
    }
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append(`uploadImages[]`, images[i]);
      }
    }

    orderService
      .exchangeOrderItem(formData)
      .then(() => {
        showInfo("Product successfully exchanged!");
        finnish();
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("exchange-order-item");
      });
  };

  return (
    <form onSubmit={handleSave}>
      <div className="view-container background-3">
        <div className="view-content">
          <div className="heading text-center">{t("Product state")}</div>
          <div className="content-card mb-8">
            <table className="info-table">
              <tbody>
                <tr>
                  <th>{t("Full Name")}:</th>
                  <td>
                    {order.firstName} {order.lastName}
                  </td>
                </tr>
                <tr>
                  <th>{t("Rider ID")}:</th>
                  <td>{order.riderId}</td>
                </tr>
                <tr>
                  <th>{t("Email")}:</th>
                  <td>
                    <LongTextDisplay text={order.email} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="flex gap-2 mb-2 flex-wrap justify-center">
            <input
              type="radio"
              id="manufacturing_fault"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.MANUFACTURING_FAULT}
              required
            />
            <label htmlFor="manufacturing_fault">
              {t("Manufacturing faultiness")}
            </label>

            <input
              type="radio"
              id="overused"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.OVERUSED}
              required
            />
            <label htmlFor="overused">{t("Overused")}</label>

            <input
              type="radio"
              id="broken"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.BROKEN}
              required
            />
            <label htmlFor="broken">{t("Broken")}</label>

            <input
              type="radio"
              id="stain"
              name="condition"
              className="radio-button"
              onChange={onConditionCheck}
              value={ReturnCondition.STAIN}
              required
            />
            <label htmlFor="stain">{t("Stain")}</label>
          </div>

          <ImageInput images={images} setImages={setImages} />

          <textarea
            className="mb-8"
            placeholder="Please write your note here!"
            ref={noteRef}
          ></textarea>

          <div className="flex justify-between">
            <button className="button" onClick={() => cancel()}>
              <i className="icon-arrow-left button__icon button__icon--prefix"></i>
              {t("Back")}
            </button>
            <button type="submit" className="button button--green">
              {t("Save")}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ItemCondition;
