import { useTranslation } from "react-i18next";
import { KitModel } from "../../Api/Models/product.model";
import { kitService } from "../../Api/Services/KitService";
import { useLoading } from "../../Context/LoadingProvider";

interface NewKitInstructionsProps {
  kit: KitModel;
  cancel: CallableFunction;
  finnish: CallableFunction;
}

const NewKitInstructions: React.FunctionComponent<NewKitInstructionsProps> = ({
  kit,
  cancel,
  finnish,
}) => {
  const { t } = useTranslation();
  const { startLoading, finnishLoading } = useLoading();

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    startLoading("preapare-kit");
    kitService
      .prepareKit(kit)
      .then(() => {
        finnish();
      })
      .finally(() => {
        finnishLoading("preapare-kit");
      });
  };

  return (
    <div className="view-container background-2">
      <div className="view-content">
        <div className="heading text-center">{t("New Kit Instructions")}</div>

        <form className="card mb-10" onSubmit={handleSave}>
          {kit.products.map((product) => (
            <div
              className="flex justify-between items-start gap-3 mb-3"
              key={product.id + "-" + product.variationId}
            >
              <div className="flex items-center gap-3">
                <img
                  src={product.image}
                  className="image image--w35"
                  alt="Product"
                />
                <strong>
                  <span className="text-blue">{product.quantity}</span> x{" "}
                  {product.name}
                </strong>
              </div>
              <label className="checkbox checkbox--large">
                <input type="checkbox" id="sanitization-step-1" required />
                <div className="checkbox__icon"></div>
              </label>
            </div>
          ))}

          <div className="flex justify-center mt-6">
            <button className="button button--blue" type="submit">
              {t("Save")}
            </button>
          </div>
        </form>

        <div className="flex justify-center">
          <button className="button" type="button" onClick={() => cancel()}>
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewKitInstructions;
