import { GoalModel } from "../Models/goal.model";
import { WarrantyModel } from "../Models/warranty.model";
import request, { Method } from "../request";

class WarrantyService {
  async getGoal() {
    return request<GoalModel>(`/warranty/goal`, Method.GET);
  }

  async getWarranty(warrantyId: number) {
    return request<WarrantyModel>(`/warranty/${warrantyId}`, Method.GET);
  }

  async listWarrantyReplacements() {
    return request<WarrantyModel[]>(`/warranty/list`, Method.GET);
  }

  async getWarrantyDataFromQr(data: string) {
    return request<WarrantyModel>(`/qr/warranty`, Method.POST, {
      data: {
        data: data,
      },
    });
  }

  async dispatch(warrantyId: number) {
    return request<WarrantyModel>(`/warranty/dispatch`, Method.POST, {
      data: {
        warranty_id: warrantyId,
      },
    });
  }

  async undoDispatch(warrantyId: number) {
    return request<WarrantyModel>(`/warranty/undo-dispatch`, Method.POST, {
      data: {
        warranty_id: warrantyId,
      },
    });
  }

  async refuse(warrantyId: number) {
    return request<WarrantyModel>(`/warranty/refuse`, Method.POST, {
      data: {
        warranty_id: warrantyId,
      },
    });
  }

  async undoRefuse(warrantyId: number) {
    return request<WarrantyModel>(`/warranty/undo-refuse`, Method.POST, {
      data: {
        warranty_id: warrantyId,
      },
    });
  }
}

export const warrantyService = new WarrantyService();
