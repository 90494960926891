import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface NotFoundPageProps {}

const NotFoundPage: React.FunctionComponent<NotFoundPageProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <h1>404</h1>
      <Link to="/">
        {"<-"}
        {t("Back")}
      </Link>
    </>
  );
};

export default NotFoundPage;
