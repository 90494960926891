import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLoading } from "../../Context/LoadingProvider";
import { useUser } from "../../Context/UserProvider";
import QrReaderModal from "./QrReaderModal";
import QrCodeImage from "../../Assets/gfx/qrcode.png";
import OrderListIcon from "../../Assets/gfx/icons/nav/order-list.svg";
import ReturnListIcon from "../../Assets/gfx/icons/nav/return-list.svg";
import ExchangeListIcon from "../../Assets/gfx/icons/nav/exchange-list.svg";
import StockList from "../../Assets/gfx/icons/nav/stock.png";
import Sanitization from "../../Assets/gfx/icons/nav/sanitization.svg";
import NewKit from "../../Assets/gfx/icons/nav/new-kit.svg";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";

interface FunctionsMenuProps {}

const FunctionsMenu: React.FunctionComponent<FunctionsMenuProps> = () => {
  const navigate = useNavigate();
  const { startLoading, finnishLoading } = useLoading();
  const { logout } = useUser();
  const [showQrReader, setShowQrReader] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleLogout = () => {
    startLoading("logout");
    logout()
      .then(() => {
        navigate("/");
      })
      .finally(() => {
        finnishLoading("logout");
      });
  };

  const closeQrReaderModal = () => {
    setShowQrReader(false);
  };

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <LanguageSelector />

        <QrReaderModal show={showQrReader} close={closeQrReaderModal} />

        <div className="flex justify-center">
          <div className="scan-qr bg-white rounded-xl mb-8">
            <div
              className="scan-qr__image"
              onClick={() => setShowQrReader(true)}
            >
              <div className="scan-qr__corners">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <img src={QrCodeImage} alt="Qr reader" />
            </div>
            <div className="scan-qr__text">{t("scan qr")}</div>
          </div>
        </div>
        <div className="limited-width-content">
          <div className="card-list">
            <Link to="/orders/list" className="nav-card">
              <img
                src={OrderListIcon}
                className="nav-card__icon"
                alt="Order list"
              />
              <div className="nav-card__title">{t("Order list")}</div>
            </Link>

            <Link to="/return/list" className="nav-card">
              <img
                src={ReturnListIcon}
                className="nav-card__icon"
                alt="Return list"
              />
              <div className="nav-card__title">{t("Return list")}</div>
            </Link>

            <Link to="/warranty/list" className="nav-card">
              <img
                src={ExchangeListIcon}
                className="nav-card__icon"
                alt="Exchange list"
              />
              <div className="nav-card__title">{t("Exchange list")}</div>
            </Link>
            <Link to="/stock/list" className="nav-card">
              <img
                src={StockList}
                className="nav-card__icon"
                alt="Stock list"
              />
              <div className="nav-card__title">{t("Stock")}</div>
            </Link>

            <Link to="/sanitization/list" className="nav-card">
              <img
                src={Sanitization}
                className="nav-card__icon"
                alt="Sanitizazion list"
              />
              <div className="nav-card__title">{t("Sanitization")}</div>
            </Link>

            <Link to="/new-kit/list" className="nav-card">
              <img src={NewKit} className="nav-card__icon" alt="New Kit list" />
              <div className="nav-card__title">{t("New kit")}</div>
            </Link>

            <div className="text-center mt-12">
              <button
                type="submit"
                className="button button--orange button--small"
                onClick={handleLogout}
              >
                {t("Logout")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FunctionsMenu;
