import QrScanner from "qr-scanner";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { orderService } from "../../Api/Services/OrderService";
import { returnService } from "../../Api/Services/ReturnService";
import { warrantyService } from "../../Api/Services/WarrantyService";
import { useLoading } from "../../Context/LoadingProvider";

interface QrReaderModalProps {
  show: boolean;
  close: CallableFunction;
}

const ValidQrTypes = ["order", "warranty", "return"];

const QrReaderModal: React.FunctionComponent<QrReaderModalProps> = ({
  show,
  close,
}) => {
  const qrVideoRef = useRef<HTMLVideoElement | null>(null);
  const qrScanner = useRef<QrScanner>();
  const [qrResult, setQrResult] = useState<string | null>(null);
  const { t } = useTranslation();

  const { startLoading, finnishLoading } = useLoading();
  const navigate = useNavigate();

  useEffect(() => {
    const clearQrScanner = () => {
      if (qrScanner.current) {
        qrScanner.current.stop();
        qrScanner.current.destroy();
        qrScanner.current = undefined;
        document
          .querySelectorAll(
            ".scan-region-highlight, .scan-region-highlight-svg, .code-outline-highlight"
          )
          .forEach((elem) => {
            elem.remove();
          });
      }
    };

    return () => {
      clearQrScanner();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!qrResult) {
      return;
    }

    try {
      JSON.parse(qrResult);
    } catch (error) {
      alert(t("Invalid QR code!"));
      return;
    }

    const qrData = JSON.parse(qrResult);
    console.log(qrData);

    if (
      "data" in qrData &&
      "type" in qrData &&
      ValidQrTypes.includes(qrData.type)
    ) {
      if (qrData.type === "order") {
        startLoading("qr-get-order-data");
        orderService
          .getOrderDataFromQr(qrData.data)
          .then((orderData) => {
            navigate(`/orders/${orderData.id}`, {
              state: {
                orderData: orderData,
              },
            });
          })
          .catch((e) => {
            alert(t("Invalid QR code!"));
          })
          .finally(() => {
            setQrResult(null);
            finnishLoading("qr-get-order-data");
          });
      } else if (qrData.type === "warranty") {
        startLoading("qr-get-warranty-data");
        warrantyService
          .getWarrantyDataFromQr(qrData.data)
          .then((warrantyData) => {
            navigate(`/warranty/${warrantyData.id}`, {
              state: {
                warrantyData: warrantyData,
              },
            });
          })
          .catch((e) => {
            alert(t("Invalid QR code!"));
          })
          .finally(() => {
            setQrResult(null);
            finnishLoading("qr-get-warranty-data");
          });
      } else if (qrData.type === "return") {
        startLoading("qr-get-return-data");
        returnService
          .getReturnDataFromQr(qrData.data)
          .then((returnData) => {
            navigate(`/return/${returnData.id}`, {
              state: {
                returnData: returnData,
              },
            });
          })
          .catch((e) => {
            alert(t("Invalid QR code!"));
          })
          .finally(() => {
            setQrResult(null);
            finnishLoading("qr-get-return-data");
          });
      } else {
        alert(t("Unknown Qr code"));
      }
    }
    // eslint-disable-next-line
  }, [qrResult]);

  useEffect(() => {
    if (qrVideoRef.current) {
      if (!qrScanner.current) {
        qrScanner.current = new QrScanner(
          qrVideoRef.current,
          (result) => {
            if (result?.data) {
              setQrResult(result.data);
            }
          },
          {
            preferredCamera: "environment",
            highlightScanRegion: true,
            highlightCodeOutline: true,
          }
        );
      }

      if (show) {
        qrScanner.current.start();
      } else {
        qrScanner.current.stop();
        setQrResult(null);
      }
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <div
      className={"modal" + (!show ? " hidden" : "")}
      tabIndex={-1}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal__dialog modal-dialog--full-height">
        <div className="modal__header">
          <div className="modal__heading">{t("Scan QR")}</div>
          <div className="modal__close" onClick={() => close()}>
            ✖
          </div>
        </div>
        <div className="modal__body">
          <video ref={qrVideoRef} />
        </div>
      </div>
    </div>
  );
};

export default QrReaderModal;
