import { GoalModel } from "../Models/goal.model";
import { ReturnCondition } from "../Models/return.model";
import {
  SanitizationModel,
  SanitizationStepModel,
  StepImages,
} from "../Models/sanitization.model";
import request, { Method } from "../request";

class SanitizationService {
  async getGoal() {
    return request<GoalModel>(`/sanitization/goal`, Method.GET);
  }

  async getSteps(productId: number) {
    return request<SanitizationStepModel[]>(
      `/sanitization/steps/${productId}`,
      Method.GET
    );
  }

  async getSizeChangeSteps(productId: number) {
    return request<SanitizationStepModel[]>(
      `/sanitization/steps-size-change/${productId}`,
      Method.GET
    );
  }

  async listSanitizations() {
    return request<SanitizationModel[]>(`/sanitization/list`, Method.GET);
  }

  async finnishSanitization(sanitizationId: number, images: StepImages[]) {
    let formData = new FormData();
    formData.append("sanitization_id", sanitizationId.toString());
    images.forEach((stepImage) => {
      if (stepImage.images) {
        for (var i = 0; i < stepImage.images.length; i++) {
          formData.append(
            `images[step-${stepImage.step}][]`,
            stepImage.images[i]
          );
        }
      }
    });

    return request(`/sanitization/update`, Method.POST, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async throwAway(
    sanitizationId: number,
    deleteNote: string,
    deleteConditionId: ReturnCondition,
    images: File[]
  ) {
    let formData = new FormData();
    formData.append("sanitization_id", sanitizationId.toString());
    formData.append("delete_note", deleteNote);
    formData.append("delete_condition_id", deleteConditionId.toString());

    for (var i = 0; i < images.length; i++) {
      formData.append(`images[]`, images[i]);
    }

    return request(`/sanitization/delete`, Method.POST, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const sanitizationService = new SanitizationService();
