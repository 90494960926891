import { OrderProduct } from "../../../Api/Models/order.model";

interface KitProps {
  item: OrderProduct;
}

const Kit: React.FunctionComponent<KitProps> = ({ item }) => {
  return (
    <div className="card mb-2">
      <div className="flex items-center justify-between gap-4 mb-2">
        <div className="modify-gear-card__header">
          <img
            src={item.image ?? ""}
            className="modify-gear-card__image"
            alt="Product"
          />
          <div className="modify-gear-card__title">{item.name}</div>
        </div>
        {/*<div className="text-small font-semibold">
          {t("Status")}:&nbps;
          <span className="modify-gear-card__status-text text-orange">
            Sanitized
          </span>
        </div>*/}
      </div>
    </div>
  );
};

export default Kit;
