import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import English from "./Translations/en.json";
import Arabic from "./Translations/ar.json";
import Hindi from "./Translations/hi.json";
import Urdu from "./Translations/ur.json";
import EnglishFlag from "./Assets/flags/en.svg";
import ArabicFlag from "./Assets/flags/ar.svg";

const resources = {
  en: English,
  ar: Arabic,
  hi: Hindi,
  ur: Urdu,
};

export interface Language {
  flag: string;
  name: string;
  key: string;
  rtl: boolean;
}

export const languages: Language[] = [
  {
    flag: EnglishFlag,
    name: "English",
    key: "en",
    rtl: false,
  },
  {
    flag: ArabicFlag,
    name: "Arabic",
    key: "ar",
    rtl: true,
  },
];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("language") ?? "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

let htmlElement = document.getElementById("html");
let currentLang = languages.find(
  (x) => x.key === localStorage.getItem("language")
);
if (htmlElement && currentLang) {
  htmlElement.dir = currentLang.rtl ? "rtl" : "ltr";
  htmlElement.lang = currentLang.key;
}

export default i18n;
