import { useTranslation } from "react-i18next";
import { OrderProduct } from "../../../Api/Models/order.model";
import { ProductVariationModel } from "../../../Api/Models/product.model";

interface VariableProductProps {
  item: OrderProduct;
  variations: ProductVariationModel[];
  changeProductVariation: CallableFunction;
  setExchangeProduct: CallableFunction;
}

const VariableProduct: React.FunctionComponent<VariableProductProps> = ({
  item,
  variations,
  changeProductVariation,
  setExchangeProduct,
}) => {
  const { t } = useTranslation();

  return (
    <div className="card mb-2" key={JSON.stringify(item)}>
      <div className="flex gap-2 justify-between items-start">
        <div className="modify-gear-maincard__header">
          <img
            src={item.image ?? ""}
            className="modify-gear-maincard__image"
            alt="Product"
          />
          <div className="modify-gear-maincard__title">{item.name}</div>
        </div>
        <div
          className="button button--small button--neongreen"
          onClick={() => setExchangeProduct(item)}
        >
          {t("Exchange")}
        </div>
      </div>
      <div className="text-small text-center mb-4">
        {t("Select a new variation")}:
      </div>
      <div className="flex gap-4 justify-center">
        {variations?.map((variation) => (
          <label className="checkbox" key={variation.id}>
            <input
              type="radio"
              name={"variation_id_" + item.id}
              checked={item.variationId === variation.id}
              value={variation.id}
              onChange={() => {
                changeProductVariation(item.id, variation.id);
              }}
            />
            <div className="checkbox__icon"></div>
            <div className="checkbox__text">
              <strong>{variation.name}</strong>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};

export default VariableProduct;
