import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { authTokenStorageKey } from "../Context/UserProvider";

export const apiEndpointStorageKey = "apiEndpoint";

export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export interface LaravelValidationError {
  message: string;
  errors: Record<string, string[]>;
}

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers) {
    const authToken = localStorage.getItem(authTokenStorageKey);

    if (authToken) {
      //eslint-disable-next-line
      config.headers.Authorization = `Bearer ${authToken}`;
    }
  }

  config.withCredentials = true;
  return config;
});

axios.interceptors.response.use(
  async (response) => response,
  async (error: AxiosError) => {
    return Promise.reject(error.response?.data);
  }
);

async function request<T = void>(
  endpoint: string,
  method: Method = Method.GET,
  requestParams: Object = {},
  api: boolean = true
) {
  let serverBaseUrl =
    localStorage.getItem(apiEndpointStorageKey) ??
    process.env.REACT_APP_API_BASE_URL;

  if (api) {
    serverBaseUrl += "/api";
  }

  const url = serverBaseUrl + endpoint;

  const requestConfig = {
    method,
    url,
    ...requestParams,
  };

  const { data: response } = await axios.request<T>(requestConfig);

  return response;
}

export default request;
