import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useUser } from "./Context/UserProvider";
import Layout from "./Layout";
import ForgotPassowrd from "./Pages/ForgotPassword";
import FunctionsMenu from "./Pages/FunctionMenu/FunctionsMenu";
import Login from "./Pages/Login";
import NewKitList from "./Pages/NewKit/NewKitList";
import NotFoundPage from "./Pages/NotFoundPage";
import OrderList from "./Pages/Order/OrderList";
import ShowOrder from "./Pages/Order/ShowOrder";
import ReturnList from "./Pages/Return/ReturnList";
import ShowReturn from "./Pages/Return/ShowReturn";
import SanitizationList from "./Pages/Sanitization/SanitizationList";
import StockList from "./Pages/Stock/StockList";
import ShowStockOrder from "./Pages/Stock/StockOrders/ShowStockOrder";
import StockOrderList from "./Pages/Stock/StockOrders/StockOrderList";
import ShowWarranty from "./Pages/Warranty/ShowWarranty";
import WarrantyList from "./Pages/Warranty/WarrantyList";

export interface IAppProps {}

const PrivateRoutes: React.FunctionComponent<IAppProps> = () => {
  const { user } = useUser();
  const location = useLocation();

  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/" replace state={{ redirectPath: location.pathname }} />
  );
};

const GuestRoutes: React.FunctionComponent<IAppProps> = () => {
  const { user } = useUser();
  const { state } = useLocation();

  return !user ? (
    <Outlet />
  ) : (
    <Navigate to={state?.redirectPath ?? "/menu"} replace />
  );
};

const App: React.FunctionComponent<IAppProps> = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<GuestRoutes />}>
            <Route index element={<Login />}></Route>
            <Route path="forgot-password" element={<ForgotPassowrd />}></Route>
          </Route>

          <Route path="/" element={<PrivateRoutes />}>
            <Route index path="menu" element={<FunctionsMenu />}></Route>

            <Route path="orders">
              <Route path="list" element={<OrderList />} />
              <Route path=":orderId" element={<ShowOrder />} />
            </Route>

            <Route path="return">
              <Route path="list" element={<ReturnList />} />
              <Route path=":returnId" element={<ShowReturn />} />
            </Route>

            <Route path="warranty">
              <Route path="list" element={<WarrantyList />} />
              <Route path=":warrantyId" element={<ShowWarranty />} />
            </Route>

            <Route path="stock">
              <Route path="list" element={<StockList />} />
              <Route path="orders">
                <Route path="list" element={<StockOrderList />} />
                <Route path=":orderId" element={<ShowStockOrder />} />
              </Route>
            </Route>

            <Route path="sanitization">
              <Route path="list" element={<SanitizationList />} />
            </Route>

            <Route path="new-kit">
              <Route path="list" element={<NewKitList />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
