import { OrderModel } from "../Models/order.model";
import { StockModel } from "../Models/stock.model";
import request, { Method } from "../request";

export interface OrderDataInterface {
  product_id: number;
  product_variation_id: number | null;
  quantity: number;
  checked: boolean;
}

class StockService {
  async listStocks() {
    return request<StockModel[]>(`/stock/list`, Method.GET);
  }

  async createOrder(products: OrderDataInterface[], note: string | null) {
    return request(`/stock/order`, Method.POST, {
      data: {
        products: products,
        note: note,
      },
    });
  }

  async listStockOrders() {
    return request<OrderModel[]>(`/stock/orders/list`, Method.GET);
  }

  async dispatch(orderId: number, images: File[]) {
    return request<OrderModel>(`/stock/orders/dispatch`, Method.POST, {
      data: {
        order_id: orderId,
        images: images,
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async stockCorrectionRequest(productId: number, message: string) {
    return request<OrderModel>(`/stock/correction-request`, Method.POST, {
      data: {
        product_id: productId,
        message: message,
      },
    });
  }
}

export const stockService = new StockService();
