import { useState } from "react";

interface LongTextDisplayProps {
  text: string;
}

const LongTextDisplay: React.FunctionComponent<LongTextDisplayProps> = ({
  text,
}) => {
  const [showFull, setShowFull] = useState<boolean>(false);

  if (text.length > 14 && !showFull) {
    return (
      <span className="break-text" onClick={() => setShowFull(true)}>
        {text.substring(0, 11)}...
      </span>
    );
  }

  return (
    <span className="break-text" onClick={() => setShowFull(false)}>
      {text}
    </span>
  );
};

export default LongTextDisplay;
