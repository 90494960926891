import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { UserProvider } from "./Context/UserProvider";
import { LoadingProvider } from "./Context/LoadingProvider";
import InfoModalProvider from "./Context/InfoModalProvider";
import "./i18n";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <I18nextProvider i18n={i18n}>
    <LoadingProvider>
      <UserProvider>
        <InfoModalProvider>
          <App />
        </InfoModalProvider>
      </UserProvider>
    </LoadingProvider>
  </I18nextProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
