import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderProduct } from "../../../Api/Models/order.model";
import { SanitizationStepModel } from "../../../Api/Models/sanitization.model";
import { LaravelValidationError } from "../../../Api/request";
import { orderService } from "../../../Api/Services/OrderService";
import { sanitizationService } from "../../../Api/Services/SanitizationService";
import ImageInput from "../../../Components/ImageInput";
import { useInfoModal } from "../../../Context/InfoModalProvider";
import { useLoading } from "../../../Context/LoadingProvider";

interface SanitizeProductProps {
  orderProduct: OrderProduct;
  cancel: CallableFunction;
  finnish: CallableFunction;
}

const SanitizeProduct: React.FunctionComponent<SanitizeProductProps> = ({
  orderProduct,
  cancel,
  finnish,
}) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<File[]>([]);
  const { startLoading, finnishLoading } = useLoading();

  const [steps, setSteps] = useState<SanitizationStepModel[]>([]);
  const { showInfo } = useInfoModal();

  useEffect(() => {
    startLoading("get-sanitization-steps");

    if (orderProduct.productId) {
      sanitizationService
        .getSizeChangeSteps(orderProduct.productId)
        .then((steps) => {
          setSteps(steps);
        })
        .finally(() => {
          finnishLoading("get-sanitization-steps");
        });
    }
    //eslint-disable-next-line
  }, []);

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    startLoading("change-order-item-size");
    let formData = new FormData();
    formData.append("new_size_id", orderProduct.variationId.toString());
    formData.append("order_item_id", orderProduct.id.toString());
    if (images) {
      for (var i = 0; i < images.length; i++) {
        formData.append(`images[]`, images[i]);
      }
    }

    orderService
      .orderItemChangeSize(formData)
      .then(() => {
        showInfo("Product size successfully changd!");
        finnish();
      })
      .catch((error: LaravelValidationError) => {
        if (error.errors?.errorMessage) {
          showInfo(error.errors?.errorMessage);
        }
      })
      .finally(() => {
        finnishLoading("change-order-item-size");
      });
  };

  return (
    <div className="view-container background-2">
      <form className="view-content" onSubmit={handleSave}>
        <div className="heading text-center">{t("Sanitize Gear")}</div>

        <div className="card mb-10">
          <div className="mb-8 flex items-center gap-3">
            <img
              src={orderProduct.image ?? ""}
              className="modify-gear-maincard__image"
              alt="Product"
            />
            <div className="modify-gear-maincard__title">
              {orderProduct.name}
            </div>
          </div>

          {steps.map((step) => (
            <div>
              <label className="checkbox mb-2">
                <input type="checkbox" name="checklist" required />
                <div className="checkbox__success-icon"></div>
                <div className="checkbox__text">{step.text}</div>
              </label>
            </div>
          ))}

          <ImageInput images={images} setImages={setImages} />
        </div>

        <div className="flex justify-center mb-6">
          <button type="submit" className="button button--blue">
            Save
          </button>
        </div>

        <div className="flex justify-center">
          <button className="button" type="button" onClick={() => cancel()}>
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default SanitizeProduct;
