export enum WarrantyStatus {
  WAITING = 1,
  REFUSED = 2,
  DISPATCHED = 3,
}

export interface WarrantyModel {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  riderId: string;
  orderDate: string;
  status: WarrantyStatus;
  productName: string;
}
