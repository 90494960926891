import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrderModel, OrderProduct } from "../../../Api/Models/order.model";
import { ProductModel } from "../../../Api/Models/product.model";
import VariableProduct from "./VariableProduct";
import SanitizeProduct from "./SanitizeProduct";
import ItemCondition from "./ItemCondition";
import StandardProduct from "./StandardProduct";
import Kit from "./Kit";

interface ModifyOrderProps {
  order: OrderModel;
  cancel: CallableFunction;
  products: ProductModel[];
  setOrder: CallableFunction;
}

const ModifyOrder: React.FunctionComponent<ModifyOrderProps> = ({
  order,
  cancel,
  products,
  setOrder,
}) => {
  const [sanitizeProduct, setSanitizeProduct] = useState<OrderProduct | null>(
    null
  );

  const [exchangeProduct, setExchangeProduct] = useState<OrderProduct | null>(
    null
  );

  const { t } = useTranslation();

  const defaultOrder = useRef<OrderModel>({ ...order });

  const cancelSanitize = () => {
    if (sanitizeProduct) {
      let previousVariationId = defaultOrder?.current?.products.find(
        (x) => x.id === sanitizeProduct.id
      )?.variationId;

      if (previousVariationId) {
        changeProductVariation(sanitizeProduct.id, previousVariationId, false);
      }

      changeProductExchanged(sanitizeProduct.id, undefined);

      setSanitizeProduct(null);
    }
  };

  const finnishSanitize = () => {
    if (sanitizeProduct) {
      changeProductExchanged(sanitizeProduct.id, false);
    }
    setSanitizeProduct(null);
  };

  const changeProductExchanged = (
    itemId: number,
    exchanged: boolean | undefined
  ) => {
    let orderState: OrderModel = { ...order };
    const porductsState: OrderProduct[] = orderState.products.map((item) => {
      return {
        id: item.id,
        image: item.image,
        name: item.name,
        productId: item.productId,
        kitId: item.kitId,
        quantity: item.quantity,
        variationId: item.variationId,
        exchanged: item.id === itemId ? exchanged : item.exchanged,
      };
    });
    orderState.products = [];
    orderState.products = porductsState;
    setOrder(orderState);
  };

  const changeProductVariation = (
    itemId: number,
    newVariationId: number,
    openSanitize: boolean = true
  ) => {
    let orderState: OrderModel = { ...order };
    const porductsState: OrderProduct[] = orderState.products.map((item) => {
      let productName = item.name;

      if (item.id === itemId) {
        let productModel = products.find((x) => item.productId === x.id);
        productName = `${productModel?.name} ${
          productModel?.variations.find((x) => x.id === newVariationId)?.name
        }`;
      }

      return {
        id: item.id,
        image: item.image,
        name: productName,
        productId: item.productId,
        kitId: item.kitId,
        quantity: item.quantity,
        variationId: item.id === itemId ? newVariationId : item.variationId,
        exchanged: item.exchanged,
      };
    });
    orderState.products = porductsState;

    setOrder(orderState);
    if (openSanitize) {
      setSanitizeProduct(porductsState.find((x) => x.id === itemId) ?? null);
    }
  };

  const cancelExchange = () => {
    if (exchangeProduct) {
      changeProductExchanged(exchangeProduct.id, undefined);
    }
    setExchangeProduct(null);
  };

  const finnishExchange = () => {
    if (exchangeProduct) {
      changeProductExchanged(exchangeProduct.id, true);
    }
    setExchangeProduct(null);
  };

  if (sanitizeProduct) {
    return (
      <SanitizeProduct
        orderProduct={sanitizeProduct}
        cancel={cancelSanitize}
        finnish={finnishSanitize}
      />
    );
  }

  if (exchangeProduct) {
    return (
      <ItemCondition
        order={order}
        orderItem={exchangeProduct}
        cancel={cancelExchange}
        finnish={finnishExchange}
      />
    );
  }

  return (
    <div className="view-container background-2" key={JSON.stringify(order)}>
      <div className="view-content">
        <div className="heading text-center">{t("Modify Gear")}</div>

        <div className="card mb-2">
          <table className="info-table">
            <tbody>
              <tr>
                <th>{t("Rider ID")}:</th>
                <td>{order.riderId}</td>
              </tr>
              <tr>
                <th>{t("Order ID")}:</th>
                <td>{order.number}</td>
              </tr>
              <tr>
                <th>{t("Order date")}:</th>
                <td>{order.orderDate}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="card-list mb-12">
          {order.products.map((product) =>
            product.variationId ? (
              <VariableProduct
                key={product.id}
                variations={
                  products.find((x) => x.id === product.productId)
                    ?.variations ?? []
                }
                item={product}
                changeProductVariation={changeProductVariation}
                setExchangeProduct={setExchangeProduct}
              />
            ) : product.productId ? (
              <StandardProduct
                key={product.id}
                item={product}
                setSanitizeProduct={setSanitizeProduct}
                setExchangeProduct={setExchangeProduct}
              />
            ) : (
              <Kit key={"kit" + product.kitId} item={product} />
            )
          )}
        </div>

        {/*
        <div className="flex justify-center mb-8">
          <button className="button button--blue" onClick={() => cancel()}>
            {t("Update order")}
          </button>
        </div>
            */}

        <div className="flex justify-center">
          <button className="button" type="button" onClick={() => cancel()}>
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModifyOrder;
