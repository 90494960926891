import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { WarrantyModel, WarrantyStatus } from "../../Api/Models/warranty.model";
import { useLoading } from "../../Context/LoadingProvider";
import { warrantyService } from "../../Api/Services/WarrantyService";
import { useTranslation } from "react-i18next";
import { GoalModel } from "../../Api/Models/goal.model";

interface WarrantyListProps {}

const WarrantyList: React.FunctionComponent<WarrantyListProps> = () => {
  const [warrantyReplacements, setWarrantyReplacements] = useState<
    WarrantyModel[]
  >([]);
  const [goal, setGoal] = useState<GoalModel>();

  const { startLoading, finnishLoading } = useLoading();
  const [selectedFilter, setSelectedFiler] = useState<number>(-1);

  const { t } = useTranslation();

  useEffect(() => {
    startLoading("get-warranty-list");
    warrantyService
      .listWarrantyReplacements()
      .then((warrantyReplacements) => {
        console.log(warrantyReplacements);

        setWarrantyReplacements(warrantyReplacements);
      })
      .finally(() => {
        finnishLoading("get-warranty-list");
      });

    warrantyService.getGoal().then((dailyGoal) => {
      setGoal(dailyGoal);
    });
    //eslint-disable-next-line
  }, []);

  const getWarrantyStatusText = (warrantyStatus: WarrantyStatus): string => {
    switch (warrantyStatus) {
      case WarrantyStatus.WAITING:
        return t("pending");

      case WarrantyStatus.DISPATCHED:
        return t("dispatched");

      case WarrantyStatus.REFUSED:
        return t("declined");
      default:
        return "";
    }
  };

  return (
    <div className="view-container background-3">
      <div className="view-content">
        <div className="heading text-center">{t("Exchange list")}</div>
        <div className="flex justify-center">
          <div className="pc-bubble mb-4">
            {
              warrantyReplacements.filter(
                (warrantyReplacement) =>
                  selectedFilter === -1 ||
                  warrantyReplacement.status === selectedFilter
              ).length
            }
            &nbsp;pc.
          </div>
        </div>
        <div className="limited-width-content">
          <div className="flex justify-center">
            <div className="card card--magenta mb-4 ml-6 mr-6">
              <div className="text-center mb-4">
                <strong>
                  <u>{t("Daily Goal")}</u>
                </strong>
              </div>
              <div className="text-center">
                <b>
                  {goal?.today ?? 0}/{goal?.goal ?? 0}
                </b>
              </div>
            </div>
          </div>

          <div className="flex justify-end mb-2 ">
            <div className="select-minimal-wrapper mr-2">
              <select
                dir="rtl"
                onChange={(e) => setSelectedFiler(parseInt(e.target.value))}
              >
                <option value={-1}>{t("All")}</option>
                <option value={1}>{t("Pending")}</option>
                <option value={3}>{t("Dispatched")}</option>
                <option value={2}>{t("Declined")}</option>
              </select>
            </div>
          </div>
          <div className="card-list mb-12 limited-height-content">
            {warrantyReplacements
              .filter(
                (warrantyReplacement) =>
                  selectedFilter === -1 ||
                  warrantyReplacement.status === selectedFilter
              )
              .map((warrantyReplacement) => (
                <Link
                  to={`/warranty/${warrantyReplacement.id}`}
                  state={{ warrantyData: warrantyReplacement }}
                  className="order-list-card"
                  key={warrantyReplacement.id}
                >
                  <div className="flex">
                    <div className="order-list-card__left">
                      <div className="order-list-card__name">
                        {warrantyReplacement.firstName}{" "}
                        {warrantyReplacement.lastName}
                      </div>
                      <div className="order-list-card__info">
                        <strong>{t("Order date")}:</strong>{" "}
                        {warrantyReplacement.orderDate}
                        <br />
                        {warrantyReplacement.productName}
                      </div>
                    </div>

                    <div className="order-list-card__right">
                      <div
                        className={
                          "order-list-card__status " +
                          getWarrantyStatusText(warrantyReplacement.status)
                        }
                      ></div>
                    </div>
                  </div>
                  <div className="flex justify-end">
                    {getWarrantyStatusText(warrantyReplacement.status)}
                  </div>
                </Link>
              ))}
          </div>
        </div>

        <div className="flex justify-center mt-4">
          <Link to="/menu" className="button">
            <i className="icon-arrow-left button__icon button__icon--prefix"></i>
            {t("Back")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WarrantyList;
